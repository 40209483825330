import React, { FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import UniversityCarouselDrag from "./UniversityCarouselDrag"
import { Container } from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"
interface IUniversity {
  title: string

  slug: string
  UniversityInfo: Array<IInfo>
}
interface IInfo {
  isInUk: boolean
  desc: string
  universityLocationCityForSearch: string
}
interface UniversityCarouselAlphabetProps {
  data: Array<IUniversity>
}

function check(e) {
  if (e.UniversityInfo.universityLocationCityForSearch != null) {
    return e.UniversityInfo.universityLocationCityForSearch
  } else {
    return e.title
  }
}
const UniversityCarouselAlphabet: FunctionComponent<UniversityCarouselAlphabetProps> = ({ data }) => {
  const [universities, setUniversities] = useState([])
  const [sortType, setSortType] = useState("all")
  const [sortLetter, setSortLetter] = useState("all")
  const [resultAlphabet, setResultAlphabet] = useState([])

  // split them into alphabatised groups
  // Check the international option
  // Check the letter filter option


  
  function sortUnits(){
      // filter out the uni's here that 
      let unis = data
      if (sortType === "uk") {
        unis = data.filter(uni => uni.UniversityInfo.isInUk)
      }
      if (sortType === "international") {
        unis = data.filter(uni => !uni.UniversityInfo.isInUk)
      }
    let sorted = [...unis].sort((a, b) => check(a).localeCompare(check(b)))
    setUniversities(sorted)
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      sortUnits()
    }
    return () => {
      isMounted = false
    }
  }, [sortType])

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      const groupedAlphabet = universities.reduce((r, e) => {
        // get first letter of name of current element
        let group
        if (e.UniversityInfo.universityLocationCityForSearch != null) {
          group = e.UniversityInfo.universityLocationCityForSearch[0]
        } else {
          group = e.title[0]
        }
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e], isInUk: e.UniversityInfo.isInUk }
        // if there is push current element to children array for that letter
        else r[group].children.push(e)
        // return accumulator
        return r
      }, {})
      const resultAlphabet = Object.values(groupedAlphabet)
      setResultAlphabet(resultAlphabet)
    }
    return () => {
      isMounted = false
    }
  }, [universities])


  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      sortUnits()
    }
    return () => {
      isMounted = false
    }
  }, [])



  const AlphabetSelection = (
    <select className="select" onChange={e => setSortLetter(e.target.value)} value={sortLetter}>
      <option className="option" value="all">
        Browse by letter
      </option>
      {resultAlphabet.map((item, i) => {
        return (
          <option key={i} value={i}>
            {item.group}
          </option>
        )
      })}
    </select>
  )

  const rads = [
    {
      label: "All",
      sortType: "all",
    },
    {
      label: "UK",
      sortType: "uk",
    },
    {
      label: "International",
      sortType: "international",
    },
  ]
  const CountryRadioButton = rads.map((v, i) => (
    <>
      <input
        type={"radio"}
        id={"region-radio-" + i}
        name={"region"}
        value={v.sortType}
        checked={v.sortType === sortType}
        onChange={e => {
          setSortType(v.sortType)
          setSortLetter("all")
        }}
      />
      <label  htmlFor={"region-radio-" + i}>
        {v.label}
      </label>
    </>
  ))

  return (
    <>
      <Container>
        <SelectionPanel>
          {AlphabetSelection}

          <HorizDivider />
          <ButtonContainer>{CountryRadioButton.map(v => v)}</ButtonContainer>
        </SelectionPanel>
      </Container>

      <Centered>
        {resultAlphabet.map((item, j) => <UniversityCarouselDrag key={uuidv4()} id={item.group} data={item.children} 
          isHidden={sortLetter !== "all" && "" + j !== sortLetter ? true : false} />
        )}
      </Centered>
    </>
  )
}

export default UniversityCarouselAlphabet

const Centered = styled.div`
  margin: auto;
  width: 100%;

  margin-bottom: 120px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 20px;
  }
`
const SelectionPanel = styled.div`
  display: flex;
  flex-direction: row;
  height: 70px;
  align-items: center;
  margin: 0px auto 20px;
  max-width: 1200px;
  border-bottom: 1px solid #ddd;
  padding: 14px 0;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    border-bottom: none;
    height: 140px;
    margin-bottom: 0px;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  column-gap: 9px;
  flex-direction: row;
  :nth-child(2) {
    margin: 0 10px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    justify-content: space-between;
    width: 100%;
    margin: 10px 0px;
    column-gap: px;
    margin: 10px 0px;
  }
`

const HorizDivider = styled.div`
  height: 100%;
  background-color: #ececec;
  margin: 0 26px;

  width: 1px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`
const DropdownContainer = styled.div`
  display: none;
  height: 100%;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    height: 100%;
  }
`
