import React from "react"
import PageHeading from "../components/PageHeading/PageHeading"

import { Layout } from "../components/layout"

import UniversityCarouselAlphabet from "../components/UniversitiesCarousel/UniversityCarouselsAlphabet"

import { graphql } from "gatsby"
import { Container } from "@material-ui/core"
import { LazyBlockFactory } from "../util/LazyBlockFactory"

const breadcrumbData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Universities",
    url: "/universities",
  },
]

const Universities: React.FC = ({ data }) => {
  const universitiesData = data.allWpUniversity.edges.map(v => v.node)
  const blocks = LazyBlockFactory(data.wpPage.lazy_data, data.wpPage.title)

  return (
    <Layout title={"Graduate Fashion Week - Universities"}>
      <PageHeading
        breadcrumbData={breadcrumbData}
        //  content={content}
        pageTitle="Universities"
      />
      <Container maxWidth={"lg"}>{blocks.map(v => v)}</Container>

      <UniversityCarouselAlphabet data={universitiesData} />
    </Layout>
  )
}

export default Universities

//@TODO Move data into UniversityCarouselAlphabet component
export const pageQuery = graphql`
  query universities {
    allWpUniversity {
      edges {
        node {
          id
          slug
          UniversityInfo {
            featuredimage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: CONSTRAINED)
                }
              }
            }
            isInUk
            universityDescription
            universityLocationCityForSearch
          }
          title
        }
      }
    }
    wpPage(title: { eq: "University Listing" }) {
      id
      lazy_data
      title
    }
  }
`
