import React, { useLayoutEffect, useState, FunctionComponent } from "react"
import styled from "styled-components"

// import Cursor from "./Cursor"

import { Swiper, SwiperSlide } from "swiper/react"

import { UniversityCardCarousel } from "../Home/UniversityCard"

interface ICarouselItems {
  name: string
}

interface UniversityCarouselDragProps {
  id?: string
  data: Array<ICarouselItems>
  isHidden: boolean
}
const UniversityCarouselDrag: FunctionComponent<UniversityCarouselDragProps> = ({ id, data, isHidden }) => {
  const [slides, setSlides] = useState(3)
  const [padding, setPadding] = useState(20)
  const [charLimit, setCharLimit] = React.useState(140)

  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    }
    return text.substr(0, charLimit) + "..."
  }
  useLayoutEffect(() => {
    const hideArrows = () => {
      if (typeof window !== "undefined") {
        const nextBtn = document.querySelector(".swiper-button-next")
        const prevBtn = document.querySelector(".swiper-button-prev")
        if (window.innerWidth >= 1200 && data.length <= 3) {
          nextBtn.style.display = "none"
          prevBtn.style.display = "none"
        } else if (window.innerWidth < 1200 && data.length > 3) {
          nextBtn.style.display = "inline-block"
          prevBtn.style.display = "inline-block"
        }
      }
    }

    function updateSize() {
      if (typeof window !== "undefined") {
        if (window.innerWidth <= 767) {
          setSlides(window.innerWidth / (300 + padding * 3))
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
          setSlides(window.innerWidth / (350 + padding * 6.5))
        } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
          setSlides(window.innerWidth / (350 + padding))
        } else {
          setSlides(2.8)
        }
      }
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => {
      window.removeEventListener("resize", updateSize)
    }
  }, [])

  return (
    <Container isHidden={isHidden}>
      {id && <LetterHeading>{id}</LetterHeading>}
      <SwiperContainer>
        <Swiper
          className="swiper-container"
          slidesPerView={"auto"}
          navigation
          scrollbar={{
            hide: false,
          }}
        >
          <SwipperWrapper id={id} className="swiper-wrapper">
            {data.map((item, i) => {

              let img = null
              
              try {
                img = item.UniversityInfo.featuredimage.localFile.childImageSharp.gatsbyImageData
              } catch (e) {
                // console.log("no image for " + item.title)
                return null
              }

              return (
                <SwiperSlide key={i} className="carousel-drag-slide">
                  <UniversityCardCarousel
                    img={img}
                    name={item.title}
                    desc={item.UniversityInfo.universityDescription}
                    slug={item.slug}
                  />
                </SwiperSlide>
              )
            })}
          </SwipperWrapper>
        </Swiper>
      </SwiperContainer>
    </Container>
  )
}

export default UniversityCarouselDrag
interface ContainerInterface {
  isHidden: boolean
}

const Container = styled.section<ContainerInterface>`
  padding: 20px 20px 0px;
 
  overflow: hidden;
  display: ${props => (props.isHidden ? "none" : "flex")};
  opacity: ${props => (props.isHidden ? "0" : "1")};
  transition: all 0.5s;
  margin: 0 auto;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0px 20px;
    flex-direction: row;
  }
  @media (min-width: 1024px) {
    padding: 55px 3% 0 3%;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0;
  }

  .swiper-container {
    overflow: hidden;
    transition: transform 0.8s ease;
    width: 100%;
    margin-right: auto;

    transition: transform 0.4s ease;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
  .swiper {
  }
  .carousel-drag-slide {
    /* padding: 35px 37px 37px;
    background: white;
    width: 320px !important;
    min-width: 320px !important;
    transition: transform 1s ease;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05); */
    height: auto;
    margin-right: 10px;

    padding: 0px 0px 48px;
    
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      width: 360px !important;
      min-width: 360px !important;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &:hover .image__container {
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.2);
    }
    &:hover .text__container {
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.2);
    }
  }
  
}
  .swiper-scrollbar {
    position: absolute;
    height: 1px;
    width: calc(100vw - 140px);
    background: ${props => props.theme.colors.turquoise};
    left: 100px;
    bottom: 10px;

    @media screen and (min-width: 1024px) {
      width: calc(100vw - 6% - 100px);
    }
    @media screen and (min-width: 1200px) {
      width: 92%;
    }
  }
  .swiper-scrollbar-drag {
    height: 3px;
    position: absolute;
    top: -1px;
    background: ${props => props.theme.colors.turquoise};
  }

  .swiper-button-next {
    margin-left: 18px;
    left: 15px;
  }

  .swiper-button-prev {
    transform: rotate(-180deg);
    left: 0;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    opacity: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: 0px;
    width: 4.9rem;
    height: 1rem;
    margin: 0;
  }
  .swiper-button-next {
    left: 50px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ny44NCAxMS4yNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwZGIwZTc7ZmlsbC1ydWxlOmV2ZW5vZGQ7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGlkPSJDb21iaW5lZC1TaGFwZSIgY2xhc3M9ImNscy0xIiBkPSJNNDUuMzgsNiw0MS4xNiwxLjg0bDEtMSw1LjcsNS42Mi01LjcsNS42Mi0xLTFMNDUuMzgsN0gwVjZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0wLjg4KSIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    /* background-color: ${props => props.theme.colors.turquoise}; */
    background-size: 100% auto;
    background-position: center;
    width: 45px;
    height: 21px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    /* background: url(../assets/svg/long-arrow-right.svg); */
    background-repeat: no-repeat;
    background-size: 100% auto;

    background-position: center;
  }
  .swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
`

const SwiperContainer = styled.div`
  cursor: grab;
  width: 80%;
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`
const SwipperWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  box-sizing: content-box;
`

interface ILinkWrapper {
  noMarginTop?: boolean
}

const LetterHeading = styled.h4`
  color: ${props => props.theme.colors.carouselLetter};
  width: 15%;
  text-align: right;
  align-items: top;
  margin-top: 50px;

  font-size: 13rem;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 70px;
    height: 70px;
    margin-top: 10px;
    margin-bottom: -10px;
  }
`
